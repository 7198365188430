<template>
  <action-add-new
    action-url="orders/create"
    button-extra-class="mr-4"
    button-label="Creaza o comanda noua"
    dialog-width="500px"
    @saved="redirectOnSave"
  >
    <template v-slot:title>
      Creaza comanda noua
    </template>

    <template v-slot:fields="{form, errors}">

      <v-row>
        <v-col>
          <complex-customer-selector v-model="form.customer_id"/>
          <error-messages :errors="errors.customer_id"/>
        </v-col>
      </v-row>

      <template v-if="form.customer_id">

        <v-row>
          <v-col>
            <customer-shipping-address-selector v-model="form.shipping_id" :customer-id="form.customer_id"/>
            <error-messages :errors="errors.shipping_id"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <customer-billing-address-selector v-model="form.billing_id" :customer-id="form.customer_id"/>
            <error-messages :errors="errors.billing_id"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <order-payment-method-selector v-model="form.payment_method"/>
            <error-messages :errors="errors.payment_method"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <order-shipping-method-selector v-model="form.shipping_method"/>
            <error-messages :errors="errors.shipping_method"/>
          </v-col>
        </v-row>

      </template>

    </template>
  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ComplexCustomerSelector from '@/components/autocompletes/ComplexCustomerSelector'
import CustomerShippingAddressSelector from '@/components/autocompletes/CustomerShippingAddressSelector'
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CustomerBillingAddressSelector from '@/components/autocompletes/CustomerBillingAddressSelector'
import OrderPaymentMethodSelector from '@/components/autocompletes/OrderPaymentMethodSelector'
import OrderShippingMethodSelector from '@/components/autocompletes/OrderShippingMethodSelector'

export default {
  name: 'AddNewOrderDialog',
  components: {
    OrderShippingMethodSelector,
    OrderPaymentMethodSelector,
    CustomerBillingAddressSelector,
    ErrorMessages,
    CustomerShippingAddressSelector,
    ComplexCustomerSelector,
    ActionAddNew
  },
  methods: {
    redirectOnSave (order) {
      this.$router.push({
        name: 'orders-page',
        params: {
          id: order.id
        }
      })
    }
  }
}
</script>
