<template>
  <div class="select-filter">
    <v-autocomplete
      v-model="selectOption"
      :items="items"
      :label="placeholder"
      :loading="loading"
      :search-input.sync="search"
      cache-items
      class="filter-select text-sm"
      clearable
      dense
      hide-details
      item-text="name"
      item-value="id"
      outlined
      single-line
    >
      <template v-slot:selection="{item}">
        <v-list-item-content>
          {{ item.contact_name }}
          <div>
            <span v-if="item.address" class="ml-2">{{ item.address }}</span>
            <small v-if="item.contact_phone" class="ml-2">({{ item.contact_phone }})</small>
            <span v-if="item.city" class="ml-2">, {{ item.city.name }}</span>
            <span v-if="item.county" class="ml-2">, {{ item.county.name }}</span>
          </div>
        </v-list-item-content>
      </template>
      <template v-slot:item="{item}">
        <v-list-item-content>
          {{ item.contact_name }}
          <div>
            <span v-if="item.address" class="ml-2">{{ item.address }}</span>
            <small v-if="item.contact_phone" class="ml-2">({{ item.contact_phone }})</small>
            <span v-if="item.city" class="ml-2">, {{ item.city.name }}</span>
            <span v-if="item.county" class="ml-2">, {{ item.county.name }}</span>
          </div>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  name: 'CustomerShippingAddressSelector',
  props: {
    placeholder: {
      default: 'Alege Adresa de livrare'
    },
    customerId: {
      default: 0
    }
  },
  data () {
    return {
      paginatedData: [],
      loading: false,
      search: ''
    }
  },
  computed: {
    items () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        const option = val || ''
        this.$emit('input', option)
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    customerId (newId, oldId) {
      if (newId !== oldId) {
        this.loadItems()
      }
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/clients/${this.customerId}/shipping-addresses?filter[contact_name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>


<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>

